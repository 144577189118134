function createObject() {
  let example = {
    id: "PfadeNino",
    workOrder:
      "Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.",
    mediaDescription: "Nino malt folgendes Bild zu der Aufgabe 3·2",
    imageSizePercent: 40,
    image: require("@/assets/eme/Pfade/Nino/01.png"),
    pubilsName: "Nino",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Diagnoseaufgabe",
          question:
            "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/eme/Pfade/Nino/02.png"),
              solutionImage: require("@/assets/eme/Pfade/Nino/03.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/eme/Pfade/Nino/04.png"),
              solutionImage: require("@/assets/eme/Pfade/Nino/05.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/eme/Pfade/Nino/06.png"),
              solutionImage: require("@/assets/eme/Pfade/Nino/07.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/eme/Pfade/Nino/08.png"),
              solutionImage: require("@/assets/eme/Pfade/Nino/09.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Förderaufgabe",
          question:
            "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/eme/Pfade/Nino/10.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/eme/Pfade/Nino/11.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/eme/Pfade/Nino/12.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/eme/Pfade/Nino/13.png"),
            },
          ],
        },
      ],
    ],
  };

  const DA_1 =
    "Diagnoseaufgabe 1 zeigt, dass Nino eine nicht passende Plättchendarstellung der Aufgabe 3·4 zuordnet. In der Abbildung die Nino auswählt, werden Multiplikator und Multiplikand eins zu eins in Plättchen übertragen und durch eine Lücke getrennt. Es besteht daher die Vermutung, dass Nino Schwierigkeiten mit der Darstellung von Multiplikationsaufgaben hat, sowie mit der Vorstellung, die hinter der Rechenoperation liegt. ";

  const DA_2 =
    "Diagnoseaufgabe 2 lässt erkennen, dass Nino die Rechenaufgabe 3·4 richtig löst. Diese Aufgabe zeigt, dass Nino das Ergebnis der Aufgabe kennt. ";

  const DA_3 =
    "Diagnoseaufgabe 3 zeigt, dass Nino die Aufgabe 3·4 nicht richtig am Zahlenstrahl darstellt. Nino trägt  Multiplikator und Multiplikand als Zahlenwerte am Zahlenstrahl ein. Seine Bearbeitung lässt die Vermutung zu, dass er zum einen Probleme mit der Darstellung von Multiplikationsaufgaben am Zahlenstrahl hat, zum anderen kann es sein, dass er Schwierigkeiten mit der Vorstellung, die hinter der Rechenoperation liegt, hat. ";

  const DA_4 =
    "Diagnoseaufgabe 4 lässt erkennen, dass Nino noch Schwierigkeiten mit dem Verfassen von Rechengeschichten hat. Die Rechengeschichte von Nino zeigt, dass er lediglich zwei verschiedene Mengen zusammenzählt und diese, anstatt sie in einen multiplikativen Kontext zu bringen, in einen additiven Zusammenhang bringt. Die Bearbeitung legt den Schluss nahe, dass Nino Schwierigkeiten mit der Vorstellung, die hinter der Rechenoperation liegt hat. ";

  const FA_1 =
    "Mit Förderaufgabe 1 werden bedeutungsbezogene Fachworte im Bereich der Multiplikation eingeführt und mit multiplikativen Darstellungen verknüpft. Nino wird dabei vor die Aufgabe gestellt, die verschiedenen Formulierungen zur Multiplikationsaufgabe miteinander in Bezug zu setzen. Die Diagnoseaufgaben zeigen, dass Nino noch Schwierigkeiten mit dem Verständnis der Multiplikation zu haben scheint und damit Multiplikationsaufgaben in verschiedene Darstellungsformen zu übertragen.  Die Förderaufgabe kann Nino daher helfen ein Verständnis für die Multiplikation und das Interpretieren von Darstellungen zur Multiplikation aufzubauen. ";

  const FA_2 =
    "In Förderaufgabe 2 wird das zeitlich-sukzessive Verständnis von Multiplikationsaufgaben angesprochen und mit der Darstellung an Plättchen verdeutlicht. Die Aufforderung zu erklären, warum die Rechenaufgabe zu der Darstellung und Beschreibung passt, stärkt das Kind dabei, seine Gedanken zu versprachlichen. Die Diagnoseaufgaben haben gezeigt, dass gerade in der Verknüpfung von Darstellungen der Multiplikation und Rechenaufgabe Ninos Schwierigkeiten liegen. Die Aufgabe kann Nino dabei helfen, ein tieferes Verständnis der Multiplikation aufzubauen sowie multiplikative Strukturen zu erkennen und mit Rechenaufgaben zu verbinden. ";

  const FA_3 =
    "In Förderaufgabe 3 wird gezeigt, wie ein anderes Kind eine Multiplikationsaufgabe mit Plättchen darstellt und erklärt, wo es in der Darstellung die verschiedenen Elemente der Multiplikationsaufgabe wiederfindet. Die Diagnoseaufgaben zeigen, dass Nino noch Schwierigkeiten damit hat, Multiplikationsaufgaben in andere Darstellungsformen zu übertragen und multiplikative Strukturen in Darstellungen zu erkennen. Die Förderaufgabe ist deshalb gut dafür geeignet, um Nino dabei zu helfen ein tieferes Verständnis der Multiplikation aufzubauen und multiplikative Strukturen mit Rechenaufgaben zu verknüpfen. ";

  const FA_4 =
    "Förderaufgabe 4 soll Nino das Berechnen von Multiplikationsaufgaben näherbringen und ihn darin stärken, seine Rechenwege zum Ausdruck zu bringen. Die Diagnoseaufgaben geben keinen Aufschluss darüber, ob Nino Schwierigkeiten mit dem Berechnen von Multiplikationsaufgaben hat. Sie weisen stärker auf Schwierigkeiten in den Grundvorstellungen der Multiplikation und dem Darstellungswechsel hin. Diese Aufgabe scheint daher nicht geeignet, die vermuteten Probleme zu fördern. ";

  const FA_4_STAR =
    "Förderaufgabe 4 soll Nino das Berechnen von Multiplikationsaufgaben näherbringen und ihn darin stärken, seine Rechenwege zum Ausdruck zu bringen. Eine der Diagnoseaufgaben hat gezeigt, dass Nino bei dem Berechnen von Multiplikationsaufgaben keine Probleme zu haben scheint. Nino scheint eher Probleme bei den Grundvorstellungen der Multiplikation und dem Darstellungswechsel zu haben. Diese Aufgabe scheint daher nicht passend zur Förderung der vermuteten Probleme zu sein. ";

  const PASSUNG_SEHR_GUT = `
        Diese Förderaufgabe passt sehr gut zur Diagnose.
        <br />
        `;
  const PASSUNG_GUT = `
        Diese Förderaufgabe passt ganz gut zur Diagnose.
        <br />
        `;
  /*const PASSUNG_SCHLECHT = `
      Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.
      <br />
     `;*/

  const PASSUNG_SEHR_SCHLECHT = `
        Diese Aufgabe hätten wir nicht gewählt.
        <br />
        `;

        example.responses = [
            {
              id: "112",
              text: PASSUNG_GUT + DA_1 + DA_2 + FA_1,
            },
            {
              id: "113",
              text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_1,
            },
            {
              id: "114",
              text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_1,
            },
            {
              id: "123",
              text: PASSUNG_GUT + DA_2 + DA_3 + FA_1,
            },
            {
              id: "124",
              text: PASSUNG_GUT + DA_2 + DA_4 + FA_1,
            },
            {
              id: "134",
              text: PASSUNG_SEHR_GUT + DA_3 + DA_4 + FA_1,
            },
            {
              id: "212",
              text: PASSUNG_GUT + DA_1 + DA_2 + FA_2,
            },
            {
              id: "213",
              text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_2,
            },
            {
              id: "214",
              text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_2,
            },
            {
              id: "223",
              text: PASSUNG_GUT + DA_2 + DA_3 + FA_2,
            },
            {
              id: "224",
              text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_2,
            },
            {
              id: "234",
              text: PASSUNG_GUT + DA_3 + DA_4 + FA_2,
            },
            {
              id: "312",
              text: PASSUNG_GUT + DA_1 + DA_2 + FA_3,
            },
            {
              id: "313",
              text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_3,
            },
            {
              id: "314",
              text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_3,
            },
            {
              id: "323",
              text: PASSUNG_GUT + DA_2 + DA_3 + FA_3,
            },
            {
              id: "324",
              text: PASSUNG_GUT + DA_2 + DA_4 + FA_3,
            },
            {
              id: "334",
              text: PASSUNG_SEHR_GUT + DA_3 + DA_4 + FA_3,
            },
            {
              id: "412",
              text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_4_STAR,
            },
            {
              id: "413",
              text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_4,
            },
            {
              id: "414",
              text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_4,
            },
            {
              id: "423",
              text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_4_STAR,
            },
            {
              id: "424",
              text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_4_STAR,
            },
            {
              id: "434",
              text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_4,
            },
          ];

          return example;
}

export default createObject();
