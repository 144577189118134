function createObject() {
  let example = {
    id: "PfadeMiriam",
    workOrder:
      "Wählen Sie aus den vier Diagnoseaufgaben zunächst genau zwei aus. Betrachen Sie dann die Lösungen und entscheiden Sie sich für genau eine - möglichst passgenaue - Förderaufgabe.",
    mediaDescription: "Miriam bearbeitet die folgende Aufgabe:",
    imageSizePercent: 60,
    image: require("@/assets/eme/Pfade/Miriam/01.png"),
    pupilName: "Miriam",
    elements: [
      [
        {
          selectCount: 2,
          typeText: "Diagnoseaufgabe",
          question:
            "Wählen Sie nun <u>genau zwei</u> weitere Diagnoseaufgaben aus, zu denen Sie die Lösung sehen möchten, um die Diagnose zu vertiefen.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/eme/Pfade/Miriam/02.png"),
              solutionImage: require("@/assets/eme/Pfade/Miriam/03.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/eme/Pfade/Miriam/04.png"),
              solutionImage: require("@/assets/eme/Pfade/Miriam/05.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/eme/Pfade/Miriam/06.png"),
              solutionImage: require("@/assets/eme/Pfade/Miriam/07.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/eme/Pfade/Miriam/08.png"),
              solutionImage: require("@/assets/eme/Pfade/Miriam/09.png"),
            },
          ],
        },
      ],
      [
        {
          selectCount: 1,
          typeText: "Förderaufgabe",
          question:
            "Wählen Sie nun <u>eine</u> passende Förderaufgabe aus, die zu Ihren gewählten Diagnoseaufgaben passt.",
          diagnostics: [
            {
              id: 1,
              taskImage: require("@/assets/eme/Pfade/Miriam/10.png"),
            },
            {
              id: 2,
              taskImage: require("@/assets/eme/Pfade/Miriam/11.png"),
            },
            {
              id: 3,
              taskImage: require("@/assets/eme/Pfade/Miriam/12.png"),
            },
            {
              id: 4,
              taskImage: require("@/assets/eme/Pfade/Miriam/13.png"),
            },
          ],
        },
      ],
    ],
  };

  const DA_1 =
    "Diagnoseaufgabe 1 zeigt, dass Miriam Schwierigkeiten damit hat, die Aufgabe 7·3 zu lösen. Bei der Berechnung der Aufgabe macht sie einen Fehler bei der Ableitung. Miriam rechnet dabei zunächst 7·2 und addiert dann 3. Miriam scheint dabei nicht bewusst zu sein, dass sich 7·3 aus 7·2 und 7·1 zusammensetzt. Die Vermutung liegt daher nahe, dass Miriam Schwierigkeiten beim Ausgleichen von Multiplikator und Multiplikand hat. ";

  const DA_2 =
    "Diagnoseaufgabe 2 lässt erkennen, dass Miriam das Aufgabenpäckchen nicht richtig ausrechnet. Ihre falschen Ergebnisse könnten sich darauf zurückführen lassen, dass sie Multiplikator und Multiplikand falsch ausgleicht. Das heißt Miriam rechnet bspw. für 6·3 zuerst 6·2 und dann plus 3, anstatt 6·2 und 6·1 zusammenzufügen zu 6·3.  Die Diagnoseaufgabe lässt vermuten, dass Miriam Schwierigkeiten beim Ausgleichen von Faktoren hat. ";

  const DA_3 =
    "Diagnoseaufgabe 3 zeigt, dass Miriam die Aufgabe 4·6 nicht ganz richtig am Zahlenstrahl darstellt und auf das Ergebnis 26, statt 24 kommt. Die Vorgehensweise von Miriam in dieser Aufgabe zeigt, dass sie versucht die Aufgabe abzuleiten, indem sie zunächst 4·5 rechnet und am Zahlenstrahl darstellt, zum Schluss addiert sie noch eine 6. Miriam scheint daher Schwierigkeiten damit zu haben, den Multiplikand auszugleichen und nicht zu wissen, dass sich die Aufgabe 4·6, aus 4·5 und 4·1 zusammensetzt. ";

  const DA_4 =
    "Diagnoseaufgabe 4 lässt erkennen, dass es Miriam keine Schwierigkeiten bereitet die Kernaufgaben und die Aufgabe 3·7 richtig zu lösen. Nur bei der Aufgabe 7·6 kommt sie auf ein falsches Ergebnis. Miriam könnte bei der Aufgabe 7·6 durch falsches ausgleichen auf das Ergebnis 41 gekommen sein. Sie könnte zunächst 7·5 gerechnet und dann 6 dazu addiert haben. Sie hätte allerdings statt 6, sieben Einer addieren müssen, weil sich 7·6 aus 7·5 und 7·1 zusammensetzt. Dies legt den Schluss nahe, dass Miriam Schwierigkeiten mit dem Ausgleichen des Multiplikanden hat. Sie hatte keine Probleme bei der Aufgabe 3·7, bei der ihre Ausgleichstrategie funktioniert. Wenn sie über 2·7 ableitet, erhält sie das richtige Ergebnis, indem sie in einem zweiten Schritt noch 7 dazu addiert. ";

  const FA_1 =
    "In Förderaufgabe 1 wird mit Hilfe von Plättchen die Veränderung aufgezeigt, die entsteht, je nachdem ob der Multiplikator oder der Multiplikand um Eins erhöht wird. Miriam kann daran erkennen, dass es einen Unterschied für das Ableiten und anschließende Ausgleichen des Faktors macht, ob der Multiplikator oder der Multiplikand ausgeglichen wird. Außerdem wird Miriam mit Begriffen konfrontiert, die multiplikative Bedeutungen mittragen, wie ein Zweier oder 6 Einer. Die Diagnoseaufgaben haben gezeigt, dass Miriam genau damit Schwierigkeiten hat. Die Aufgabe ist daher hilfreich, um Miriam beim Ausgleichen des Multiplikanden zu helfen. ";

  const FA_2 =
    "Mit der Bearbeitung der von Förderaufgabe 2 wird mit Hilfe von Plättchen die Veränderung aufgezeigt, die entsteht, wenn der Multiplikand um Eins erhöht wird. Die Aufgabe geht dabei auf die Strategie ein, die Miriam verwendet hat, um Multiplikationsaufgaben zu lösen: Die Diagnoseaufgaben zeigen, dass Miriam versucht über Kernaufgaben abzuleiten, allerdings Schwierigkeiten damit, hat den fehlenden Rest richtig auszugleichen. Dieses Problem tritt bei ihr häufig bei dem Versuch auf, den Multiplikanden auszugleichen. Deshalb scheint die Aufgabe gut dafür geeignet zu sein, um Miriam bei ihren Schwierigkeiten zu unterstützen. ";

  const FA_3 =
    "Förderaufgabe 3 verlangt, dass Miriam die additive Schreibweise der Multiplikationsaufgabe 7·4 notiert. Miriam scheint damit allerdings keine Probleme zu haben, zumindest weisen die Diagnoseaufgaben darauf nicht hin. Auch für die Problematik von Miriam beim Ausgleichen des Multiplikanden nach dem Ableiten, scheint die additive Schreibweise von Multiplikationsaufgaben nicht sonderlich hilfreich. Die Förderaufgabe ist deshalb nicht ganz so gut dafür geeignet, Miriam in ihrem Problembereich zu fördern. ";

  const FA_4 =
    "In Förderaufgabe 4 soll Miriam die Aufgabe 3·6 sowie deren Tauschaufgabe 6·3 rechnen und diese mit Plättchen darstellen. Sie soll dabei die Darstellungen miteinander vergleichen und beschreiben, was ihr auffällt. Die Diagnoseaufgaben zeigen, dass Miriam Schwierigkeiten damit zu haben scheint, nach dem Ableiten, den Multiplikanden richtig auszugleichen. Miriam könnte die Aufgaben zunächst unterschiedlich lösen, weil ihr bei 3·6 die Ableitung und das Ausgleichen gelingt, aber bei 6·3 nicht. Dies könnte einen kognitiven Konflikt erzeugen, der ihr dabei hilft nachzuvollziehen, dass ihre Strategie so nicht funktioniert. Es bleibt allerdings ohne weitere Diagnose und Förderung unklar, inwiefern ihr dieser kognitive Konflikt dabei hilft, selbstständig eine neue Strategie zu entwickeln, um Aufgaben diesen Formats künftig sicher lösen zu können. ";

  const PASSUNG_SEHR_GUT = `
        Diese Förderaufgabe passt sehr gut zur Diagnose.
        <br />
        `;
  const PASSUNG_GUT = `
        Diese Förderaufgabe passt ganz gut zur Diagnose.
        <br />
        `;
  //const PASSUNG_SCHLECHT = `
  //    Diese Förderaufgabe hätten wir nach der Diagnose eher nicht gewählt.
  //    <br />
  //   `;

  const PASSUNG_SEHR_SCHLECHT = `
        Diese Aufgabe hätten wir nicht gewählt.
        <br />
        `;

  example.responses = [
    {
      id: "112",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_2 + FA_1,
    },
    {
      id: "113",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_1,
    },
    {
      id: "114",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_1,
    },
    {
      id: "123",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_1,
    },
    {
      id: "124",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_1,
    },
    {
      id: "134",
      text: PASSUNG_SEHR_GUT + DA_3 + DA_4 + FA_1,
    },
    {
      id: "212",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_2 + FA_2,
    },
    {
      id: "213",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_3 + FA_2,
    },
    {
      id: "214",
      text: PASSUNG_SEHR_GUT + DA_1 + DA_4 + FA_2,
    },
    {
      id: "223",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_3 + FA_2,
    },
    {
      id: "224",
      text: PASSUNG_SEHR_GUT + DA_2 + DA_4 + FA_2,
    },
    {
      id: "234",
      text: PASSUNG_SEHR_GUT + DA_3 + DA_4 + FA_2,
    },
    {
      id: "312",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_2 + FA_3,
    },
    {
      id: "313",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_3 + FA_3,
    },
    {
      id: "314",
      text: PASSUNG_SEHR_SCHLECHT + DA_1 + DA_4 + FA_3,
    },
    {
      id: "323",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_3 + FA_3,
    },
    {
      id: "324",
      text: PASSUNG_SEHR_SCHLECHT + DA_2 + DA_4 + FA_3,
    },
    {
      id: "334",
      text: PASSUNG_SEHR_SCHLECHT + DA_3 + DA_4 + FA_3,
    },
    {
      id: "412",
      text: PASSUNG_GUT + DA_1 + DA_2 + FA_4,
    },
    {
      id: "413",
      text: PASSUNG_GUT + DA_1 + DA_3 + FA_4,
    },
    {
      id: "414",
      text: PASSUNG_GUT + DA_1 + DA_4 + FA_4,
    },
    {
      id: "423",
      text: PASSUNG_GUT + DA_2 + DA_3 + FA_4,
    },
    {
      id: "424",
      text: PASSUNG_GUT + DA_2 + DA_4 + FA_4,
    },
    {
      id: "434",
      text: PASSUNG_GUT + DA_3 + DA_4 + FA_4,
    },
  ];

  return example;
}

export default createObject();
