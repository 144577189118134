<template>
  <div>
    <h2 class="faledia-caption">Diagnose & Förderung</h2>
    <p>
      Auch wenn das Hintergrundwissen zum Einmaleins wichtig ist, ist es genauso
      wichtig, dieses Wissen auf konkrete Kinderdokumente anwenden zu können. Es
      ist dabei bedeutsam, erfassen zu können, in welchen Bereichen das
      Einmaleins bereits gut ausgebildet ist und in welchen Bereichen eventuell
      noch Probleme vorliegen.
    </p>
    <p>
      Die folgende Tabelle bietet einen Überblick über einige Fehlertypen, die
      beim Einmaleins auftreten können. Die Fehlertypen können dabei in Zähl-
      und Rechenfehler sowie in Fehler beim Darstellen unterteilt werden (vgl.
      Padberg & Benz, 2021; Götze, et al., 2019; Gaidoschik, 2022):
    </p>
    <AppButtonCollapse
      messageHidden="Tabelle Zählfehler anzeigen"
      messageNotHidden="Tabelle Zählfehler verstecken"
    >
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td></td>
              <td>
                <strong>Erklärung</strong>
              </td>
              <td>
                <strong>Beispiel</strong>
              </td>
            </tr>
            <tr>
              <td>Fehler beim Aufsagen der Reihe</td>
              <td>
                Der Fehler tritt typischer Weise beim Weiterzählen in den Reihen
                auf. Dabei wird nicht beim ersten Zahlwort des Multiplikanden
                begonnen zu zählen, sondern erst bei dem zweiten Zahlwort der
                Reihe wird der erste Finger gehoben. Zudem können lange
                Zählprozesse dazu führen, dass sich die Kinder verzählen oder
                das Ergebnis einer Reihe vergessen zu zählen.
              </td>
              <td>“6 · 8? Das sind: 8, 16, 24, 32, 40, 48, 56.”</td>
            </tr>
            <tr>
              <td>Fehler beim zählenden Addieren</td>
              <td>
                Ein häufiger Fehler bei dem addierenden Zählen. Weil die Kinder
                von jedem Zwischenergebnis aus 8 weiterzählen (addieren), kann
                es schnell zu Verzählungen um ±1 kommen.
              </td>
              <td>“6 · 8? Das sind: 8, 16, 24, 31, 39, 47.”</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </AppButtonCollapse>
    <AppButtonCollapse
      messageHidden="Tabelle Rechenfehler anzeigen"
      messageNotHidden="Tabelle Rechenfehler verstecken"
    >
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td></td>
              <td>
                <strong>Erklärung</strong>
              </td>
              <td>
                <strong>Beispiel</strong>
              </td>
            </tr>
            <tr>
              <td>Fehler mit der Null und Eins</td>
              <td>
                Der Fehler kann dadurch entstehen, dass die Kinder die
                Multiplikation als Addition begreifen oder dadurch, dass sie
                meinen, dass sich bei der Multiplikation das Produkt immer
                vergrößert.
              </td>
              <td class="no-wrap">
                2 · 0 sind 2. <br />
                0 · 8 sind 8.
              </td>
            </tr>
            <tr>
              <td>Falscher Ausgleich des 1.Faktors</td>
              <td>
                Nachdem der Multiplikator verändert wurde, entsteht eine
                Unsicherheit über den Ausgleich dieser Veränderung. Kinder
                denken dann oft, dass beispielsweise 8 · 7 nicht 7, sondern 8
                mehr sind als 7 · 7.
              </td>
              <td>
                “8 · 7 sind 7 · 7 und dann kommen noch 8 dazu, weil aus dem
                1.Faktor 7 eine 8 wird.”
              </td>
            </tr>
            <tr>
              <td>Falscher Ausgleich des 2.Faktors</td>
              <td>
                Hier entstehen die Unsicherheiten bei der Ableitung zwischen den
                verschiedenen Einmaleinsreihen. Kindern ist nicht bewusst, dass
                nicht reihenübergreifend gearbeitet wird. Das sich 4 · 6 aus 4 ·
                5 plus 4 · 1 zusammensetzt, ist den Kinder nicht bewusst.
              </td>
              <td>
                “4 · 6 kann ich durch 4 · 5 berechnen. 4 · 5 plus 6 sind dann 4
                · 6.”
              </td>
            </tr>
            <tr>
              <td>Das Produkt gleicher Faktoren wird additiv gedeutet</td>
              <td>
                Anstelle der Nutzung der Quadratzahl, wird hier mit der Summe
                gearbeitet. Somit werden die einzelnen Faktoren addiert und
                nicht multipliziert.
              </td>
              <td>8 · 8 sind 16.</td>
            </tr>
            <tr>
              <td>Falsches gegensinniges Verändern (Konstanzgesetz)</td>
              <td>
                Die Rechenstrategien des gegensinnigen Veränderns in der
                Addition wir auf die Multiplikation übertragen.
              </td>
              <td>
                “2 · 4 ist das gleiche wie 3 · 3. Die erste Zahl ist eins größer
                und die zweite eins kleiner.”
              </td>
            </tr>
            <tr>
              <td>
                Multiplikatives, nicht additives Zerlegen des Faktors
                (Assoziativgesetz)
              </td>
              <td>
                Einer der Faktoren wird nach dem Assoziativgesetz zerlegt. Aber
                anstatt die Teilprodukte dann zu multiplizieren, werden sie
                addiert. Richtig wäre: 8 · 5 = (2 · 4) · 5 = 2 · (4 · 5) = 2 ·
                20 = 40.
              </td>
              <td>8 · 5 sind 2 · 5 plus 4 · 5.</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </AppButtonCollapse>
    <AppButtonCollapse
      messageHidden="Tabelle Fehler beim Darstellen  anzeigen"
      messageNotHidden="Tabelle Fehler beim Darstellen  verstecken"
    >
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td></td>
              <td>
                <strong>Erklärung</strong>
              </td>
              <td>
                <strong>Beispiel</strong>
              </td>
            </tr>
            <tr>
              <td>Mögliche Verwechslung bei Multiplikator und Multiplikand</td>
              <td>
                Die Kinder unterscheiden nicht in Multiplikator und Multiplikand
                und verwechseln diese. Es ist aber wichtig zu beachten, dass der
                Multiplikand angibt, wie oft der Multiplikator gelegt wird.
              </td>
              <td>
                “Lege zweimal drei Würfel.”
                <div class="images">
                  <AppExpandableImage
                    contain
                    :src="require('@/assets/eme/Wuerfel.png')"
                    elementId="eme_wuerfel.png"
                    parentId="eme"
                  >
                  </AppExpandableImage>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Schwierigkeiten bei Unterscheidung von Symbolen und deren
                Bedeutung
              </td>
              <td>
                Die Kinder gehen hierbei davon aus, dass es einen Malpunkt beim
                Legen mit Plättchen geben muss, da es keine zwei Mengen wie bei
                der Addition gibt.
              </td>
              <td>
                “Lege 3 · 2 mit Plättchen.”
                <div class="images">
                  <AppExpandableImage
                    contain
                    :src="require('@/assets/eme/FuenfPunkte.png')"
                    elementId="eme_plaettchen.png"
                    parentId="eme"
                  >
                  </AppExpandableImage>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </AppButtonCollapse>
    <p>
      Im nachfolgenden Beispiel haben Sie nun abschließend die Gelegenheit
      zunächst eine Feindiagnostik auf Grundlage erster diagnostischer
      Erkenntnisse durchzuführen und im Anschluss daran eine geeignete
      Förderaufgabe auszuwählen.
    </p>
    <div style="background-color: white">
      <LernbausteinPfade
        :id="LBSTPfade[0].id"
        :workOrder="LBSTPfade[0].workOrder"
        :image="LBSTPfade[0].image"
        :imageSizePercent="LBSTPfade[0].imageSizePercent"
        :mediaDescription="LBSTPfade[0].mediaDescription"
        :audio="LBSTPfade[0].audio"
        :elements="LBSTPfade[0].elements"
        :responses="LBSTPfade[0].responses"
        :pupilName="LBSTPfade[0].pupilName"
      />
    </div>
    <div style="background-color: white">
      <LernbausteinPfade
        :id="LBSTPfade[1].id"
        :workOrder="LBSTPfade[1].workOrder"
        :image="LBSTPfade[1].image"
        :imageSizePercent="LBSTPfade[1].imageSizePercent"
        :mediaDescription="LBSTPfade[1].mediaDescription"
        :audio="LBSTPfade[1].audio"
        :elements="LBSTPfade[1].elements"
        :responses="LBSTPfade[1].responses"
        :pupilName="LBSTPfade[1].pupilName"
      />
    </div>
    <AppLiteraturEME />
    <AppBottomNavEME next="/einmaleins/check" back="/einmaleins/uebergang" />
  </div>
</template>

<script>
import AppBottomNavEME from "@/common/AppBottomNavEME";
import AppLiteraturEME from "@/common/AppLiteraturEME";
import AppButtonCollapse from "@/common/AppButtonCollapse";
import AppExpandableImage from "@/common/AppExpandableImage";
import LernbausteinPfade from "@/components/faledia/explorativeElemente/LernbausteinPfade";

import LBST_Pfade_Nino from "@/components/faledia/seiten/einMalEins/LBST/LBST_Pfade_Nino";
import LBST_Pfade_Miriam from "@/components/faledia/seiten/einMalEins/LBST/LBST_Pfade_Miriam";

export default {
  components: {
    AppBottomNavEME,
    AppLiteraturEME,
    AppButtonCollapse,
    LernbausteinPfade,
    AppExpandableImage,
  },
  data: () => ({
    LBSTPfade: [LBST_Pfade_Nino, LBST_Pfade_Miriam],
  }),
};
</script>
<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
.images {
  max-width: 30%;
}
</style>
